// Home Page
import $ from 'jquery';
import Swiper from 'swiper';
import { util } from './util';

class Home {
  constructor(elem) {
    if (elem) {
      this.initHome();
    }
  }

  initHome() {
    // Main Nav Mobile
    var toggleMobileNav = function (me) {
      me = me || $('.nav-toggle a')[0];
      if ($(me).hasClass('icon-nav-toggle-off')) {
        $(me).attr('aria-expanded', 'true');
        $('body').addClass('nav-shown');
        $('.main-nav').show();
        $('.frame').hide();
        $('.frame').attr('aria-hidden', 'true');
        $('.main-nav').attr('aria-hidden', 'false');
        $(me).removeClass('icon-nav-toggle-off');
        $(me).addClass('icon-close');
      } else {
        $(me).attr('aria-expanded', 'false');
        $('body').removeClass('nav-shown');
        $('.main-nav').hide();
        $('.frame').show();
        $('.frame').attr('aria-hidden', 'false');
        $('.main-nav').attr('aria-hidden', 'true');
        $(me).addClass('icon-nav-toggle-off');
        $(me).removeClass('icon-close');
      }
    }
    $('.nav-toggle a').on('click', function (e) {
      toggleMobileNav(this);
      e.preventDefault();
    });
  }
}

// only init on home page
const homeInit = {
  init() {
    // if element exists init this class
    const wrapperEl = document.getElementById('home');
    if (wrapperEl !== null) {
      // eslint-disable-next-line no-unused-vars
      const home = new Home(wrapperEl);
    }
  }
}

export default homeInit;
