// components
import home from './scripts/home';

class App {
  constructor() {
    // home page
    home.init();
  }
}

const app = new App();
