// Named Export
// Utility

import Modernizr from './modernizr';

class Utility {
  constructor() {
    this.checkSafariMobile();
    this.checkTouch();
    this.checkBrowser();
  }

  rafThrottle(callback) {
    this.something = true;
    let requestId = null;

    let lastArgs;

    const later = context => () => {
      requestId = null;
      callback.apply(context, lastArgs);
    };

    const throttled = function(...args) {
      lastArgs = args;
      if (requestId === null) {
        requestId = requestAnimationFrame(later(this));
      }
    };

    throttled.cancel = () => {
      cancelAnimationFrame(requestId);
      requestId = null;
    };

    return throttled;
  }

  debounce(callback, wait, context = this) {
    let timeout = null;
    let callbackArgs = null;
    const later = () => callback.apply(context, callbackArgs);

    return function(...args) {
      callbackArgs = args;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  checkSafariMobile() {
    Modernizr.addTest('mobilesafari', () => /iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent));

    this.isSafariMobile = Modernizr.mobilesafari;
  }

  checkTouch() {
    if (Modernizr.touchevents) {
      this.hasTouch = true;
    } else {
      this.hasTouch = false;
    }
  }

  checkBrowser() {
    Modernizr.addTest('ie', () => {
      return /MSIE 10/i.test(navigator.userAgent) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent);
    });

    this.isIe = Modernizr.ie;
    // add ie class to <html>
    if (this.isIe) document.documentElement.classList.add('ie');
  }

  // eslint-disable-next-line class-methods-use-this
  formatMoney(cents, format = '${{amount}}') {
    if (typeof cents === 'string') cents = cents.replace('.', '');

    let value = '';
    const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    const formatString = format;

    function formatWithDelimiters(number, precision = 2, thousands, decimal) {
      thousands = thousands || ',';
      decimal = decimal || '.';

      if (Number.isNaN(number) || number == null) {
        return 0;
      }

      number = (number / 100.0).toFixed(precision);

      const parts = number.split('.');
      const dollarsAmount = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands);
      const centsAmount = parts[1] ? (decimal + parts[1]) : '';

      return dollarsAmount + centsAmount;
    }
    switch (formatString.match(placeholderRegex)[1]) {
      case 'amount':
        value = formatWithDelimiters(cents, 2);
        break;
      case 'amount_no_decimals':
        value = formatWithDelimiters(cents, 0);
        break;
      case 'amount_with_comma_separator':
        value = formatWithDelimiters(cents, 2, '.', ',');
        break;
      case 'amount_no_decimals_with_comma_separator':
        value = formatWithDelimiters(cents, 0, '.', ',');
        break;
      case 'amount_no_decimals_with_space_separator':
        value = formatWithDelimiters(cents, 0, ' ');
        break;
      default:
        value = formatWithDelimiters(cents, 2);
        break;
    }
    return formatString.replace(placeholderRegex, value);
  }

  static getSiblings(elem) {
    return Array.prototype.filter.call(elem.parentNode.children, sibling => sibling !== elem);
  }
}

export default Utility;
export const util = new Utility();
